<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Takas Araç Noter Satış Sözleşmesi</b-card-title>
    </b-card-header>
    <b-card-body>
      <div
        v-if="dataItem.swapcarSaleContract"
        class="mb-1"
      >
        <b-button
          variant="success"
          size="sm"
          :href="baseURL + '/media/sales/' + dataItem.swapcarSaleContract"
          target="_blank"
        >
          <FeatherIcon
            icon="DownloadIcon"
          />
          Sözleşme İndir
        </b-button>
        <b-button
          v-if="userData.id === dataItem.id_com_user"
          variant="danger"
          class="ml-1"
          size="sm"
          @click="deleteDocument(dataItem.swapcarSaleContract)"
        >
          <FeatherIcon
            icon="TrashIcon"
          />
          Sil
        </b-button>
      </div>
      <template v-else>
        <div v-if="userData.id === dataItem.id_com_user">
          <b-form-group
            label="Sözleşme Dosyası"
            label-for="upload_file"
          >
            <b-form-file
              v-model="file"
              placeholder="Bir dosya seçin veya buraya bırakın..."
              drop-placeholder="Dosya buraya bırakın..."
              browse-text="Dosya Seçin"
            />
          </b-form-group>
          <div class="text-right">
            <b-button
              size="sm"
              variant="primary"
              :disabled="!file || loadFile"
              @click="saveDocument"
            >
              <template v-if="!loadFile">
                <feather-icon icon="UploadIcon" />
                Yükle
              </template>
              <template v-else>
                <b-spinner
                  small
                  class="mr-1"
                  label="Small Spinner"
                />
                Yükle
              </template>

            </b-button>
          </div>
        </div>
        <b-alert
          v-else
          show
          variant="danger"
          class="m-2"
        >
          <div class="alert-body text-center">
            İlgili doküman girişi yapılmamış.
          </div>
        </b-alert>
      </template>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BButton, BFormFile, BFormGroup, BAlert, BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'SwapCarSaleContract',
  components: {
    BAlert,
    BFormGroup,
    BFormFile,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BSpinner,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      baseURL: this.$store.state.app.baseURL,
      file: null,
      loadFile: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  methods: {
    saveDocument() {
      this.loadFile = true
      this.$store.dispatch('saleOrderforms/saveDocument', {
        swapcarSaleContract: this.file,
        id_com_sale_orderform: this.dataItem.id,
        id_com_sale_document_types: 3,
      }).then(response => {
        if (response.status) {
          this.$store.dispatch('saleOrderforms/saleOrderFormsView', this.$route.params.order_number)
        }
        this.loadFile = false
      })
    },
    deleteDocument(filename) {
      this.$store.dispatch('saleOrderforms/deleteDocument', filename)
        .then(response => {
          if (response.status) {
            this.$store.dispatch('saleOrderforms/saleOrderFormsView', this.$route.params.order_number)
          }
        })
    },
  },
}
</script>
