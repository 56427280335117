<template>
  <b-form-textarea
    v-model="itemData.notes"
    placeholder="Not"
  />
</template>

<script>
import {
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  name: 'AracBedeli',
  components: {
    BFormTextarea,
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
}
</script>
