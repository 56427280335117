<template>
  <validation-provider
    #default="{ errors }"
    name="KDV Matrahından Düşülecek"
    rules="required"
  >
    <b-input-group>
      <cleave
        id="kdvdusulecek"
        v-model="itemData.invoice.kdvdusulecek"
        class="form-control"
        :raw="true"
        :options="options.price"
        placeholder="KDV Matrahından Düşülecek"
      />
      <b-input-group-append is-text>
        ₺
      </b-input-group-append>
    </b-input-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { required } from '@validations'

export default {
  name: 'KdvDusulecek',
  components: {
    BInputGroup,
    BInputGroupAppend,
    Cleave,
    ValidationProvider,
  },
  data() {
    return {
      required,
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          // numeralDecimalMark: ',',
          // delimiter: '.',
        },
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
