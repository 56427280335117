<template>
  <validation-provider
    #default="{ errors }"
    name="KDV Oranı"
    rules="required"
  >
    <v-select
      id="id_com_kdvrate"
      v-model="saleOrderForm.id_com_kdvrate"
      label="title"
      :reduce="kdvRate => kdvRate.id"
      :options="kdvRates"
      placeholder="Seçiniz"
    />
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'KdvOrani',
  components: {
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    kdvRates() {
      return this.$store.getters['kdvRates/getKdvRates']
    },
  },
  created() {
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      return this.$store.dispatch('kdvRates/kdvRatesList')
    },
  },
}
</script>
