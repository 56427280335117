<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Destek Faturaları</b-card-title>
    </b-card-header>
    <b-table-simple v-if="itemData.supportInvoices.lines.length">
      <b-thead>
        <b-tr>
          <b-th class="width-200">
            Fatura Tarihi
          </b-th>
          <b-th>Şase</b-th>
          <b-th class="text-right">
            Tutar
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="item in itemData.supportInvoices.lines"
          :key="item.id"
        >
          <b-td>{{ moment(item.fatura_tarihi).format('DD.MM.YYYY') }}</b-td>
          <b-td>{{ item.sase }}</b-td>
          <b-td class="text-right">
            {{ item.tutar | toCurrency }} ₺
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th colspan="2">
            Toplam
          </b-th>
          <b-th class="text-right">
            {{ itemData.supportInvoices.total | toCurrency }} ₺
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
    <b-card-body v-else>
      <b-alert
        variant="info"
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          Destek faturası bulunamadı.
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BAlert, BTableSimple, BTbody, BTd, BTh, BThead, BTr, BTfoot,
} from 'bootstrap-vue'

export default {
  name: 'SupportInvoices',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAlert,
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BThead,
    BTr,
    BTfoot,
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
}
</script>
