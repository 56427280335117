<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Faturalandırma</b-card-title>
      </b-card-header>
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th class="align-middle width-300">
              Oto Bedeli
            </b-th>
            <b-td>{{ itemData.invoice.otobedeli | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              ÖTV Oranı
            </b-th>
            <b-td>% {{ itemData.otv_rate }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              ÖTV Tutarı
            </b-th>
            <b-td>{{ itemData.invoice.otvtutari | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              KDV Matrahından Düşülecek
            </b-th>
            <b-td>{{ itemData.invoice.kdvdusulecek | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              KDV Matrahı
            </b-th>
            <b-td>{{ itemData.invoice.kdvmatrahi | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              KDV Oranı
            </b-th>
            <b-td>% {{ itemData.kdv_rate }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              KDV Tutarı
            </b-th>
            <b-td>{{ itemData.invoice.kdvtutari | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              Araç Bedeli
            </b-th>
            <b-td>{{ itemData.invoice.aracbedeli | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              Plaka
            </b-th>
            <b-td>{{ itemData.invoice.plate_price | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              Bandrol
            </b-th>
            <b-td>{{ itemData.invoice.banderole_price | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="align-middle"
            >
              Araç Fatura Bedeli
              <div class="font-small-2 text-primary">
                (Plaka Bandrol Dahil)
              </div>
            </b-th>
            <b-td>{{ itemData.invoice.aracfaturabedeli | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="align-middle"
            >
              Araç Satış Bedeli
              <div class="font-small-2 text-primary">
                (Aksesuar Dahil)
              </div>
            </b-th>
            <b-td>{{ itemData.invoice.aracsatisbedeli | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="align-middle"
            >
              PDI
              <div class="font-small-2 text-primary">
                (KDV Dahil Tutar Giriniz)
              </div>
            </b-th>
            <b-td>{{ itemData.invoice.pdi | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              Bakiye
            </b-th>
            <b-td>{{ itemData.invoice.bakiye | toCurrency }} ₺</b-td>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              FF
              <div class="font-small-2 text-primary">
                (KDV Hariçtir)
              </div>
            </b-th>
            <b-td>{{ itemData.invoice.fatura_farki | toCurrency }} ₺</b-td>
          </b-tr>
        </b-thead>
      </b-table-simple>
      <b-card-body v-if="itemData.notes">
        <b-alert show>
          <div class="alert-body">
            {{ itemData.notes }}
          </div>
        </b-alert>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BTableSimple, BThead, BTr, BTh, BTd, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'InvoiceResult',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BAlert,
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
}
</script>
