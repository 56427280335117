<template>
  <div>
    <div class="text-center">
      <b-button
        v-if="userData.id_com_department === '21'"
        variant="danger"
        class="mr-1"
        @click="sendCandeniz"
      >
        <FeatherIcon icon="SendIcon" />
        Trafik Müşavirine Gönder
      </b-button>
      <b-button
        v-if="userData.id_com_department === '21'"
        variant="danger"
        class="mr-1"
        @click="sendCandeniz"
      >
        <FeatherIcon icon="SendIcon" />
        Trafik Müşavirine Gönder
      </b-button>
      <b-button
        variant="success"
        class="ml-1"
        @click="deliveryModal"
      >
        <FeatherIcon icon="FileTextIcon" />
        Teslimat Formu
      </b-button>
      <b-button
        variant="info"
        class="ml-1"
        @click="proformaModal"
      >
        <FeatherIcon icon="FileTextIcon" />
        Proforma Fatura
      </b-button>
      <b-button
        variant="success"
        class="ml-1"
        :href="printForm"
        target="_blank"
      >
        <feather-icon icon="PrinterIcon" />
        Föyü Yazdır
      </b-button>
      <b-button
        v-if="userData.id_com_department === '21'"
        variant="warning"
        class="ml-1"
        @click="showReasonModal"
      >
        <feather-icon icon="RepeatIcon" />
        Yeniden Yönetici Onayına Gönder
      </b-button>
      <b-button
        :variant="Number(cancelRequest) ? 'primary':'danger'"
        class="ml-1"
        :disabled="cancelRequest ? true:false"
        @click="showCancelModal"
      >
        <feather-icon icon="TrashIcon" />
        {{ Number(cancelRequest) ? 'Föy İptali İsteği Oluşturulmuş':'Föy İptali İsteği Oluştur' }}
      </b-button>
      <b-modal
        v-model="showCancelReasonModal.status"
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Föy İptal İsteği Oluştur"
      >
        <b-form-group
          label="Föy iptal nedeniniz"
          label-for="reason"
        >
          <b-form-textarea
            id="reason"
            v-model="showCancelReasonModal.reason"
            placeholder="Giriniz.."
          />
        </b-form-group>
        <template #modal-footer>
          <b-button
            :disabled="!showCancelReasonModal.reason"
            variant="success"
            @click="sendCancelButton"
          >
            <feather-icon icon="SaveIcon" />
            Kaydet
          </b-button>
        </template>
      </b-modal>
      <b-modal
        v-model="showapproveReasonModal.status"
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Yeniden Yönetici Onayına Gönder"
      >
        <b-form-group
          label="Yeniden onaya gönderim nedeniniz"
          label-for="reason"
        >
          <b-form-textarea
            id="reason"
            v-model="showapproveReasonModal.reason"
            placeholder="Giriniz.."
          />
        </b-form-group>
        <template #modal-footer>
          <b-button
            :disabled="!showapproveReasonModal.reason"
            variant="success"
            @click="sendApproveButton"
          >
            <feather-icon icon="SaveIcon" />
            Kaydet
          </b-button>
        </template>
      </b-modal>
      <b-modal
        v-model="showModal"
        :title="modalTitle"
        ok-only
        centered
        size="lg"
        hide-footer
        ok-title="Kapat"
      >
        <component :is="modalForm" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BCard, BFormInput, BFormGroup, BButton, BModal, BFormTextarea,
} from 'bootstrap-vue'
import DeliveryForm from '@/views/Sales/Sale_orderforms/View/Forms/DeliveryForm.vue'
import ProformaForm from '@/views/Sales/Sale_orderforms/View/Forms/ProformaForm.vue'

export default {
  name: 'Buttons',
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    DeliveryForm,
    ProformaForm,
    BFormTextarea,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      showModal: false,
      showapproveReasonModal: {
        status: false,
        reason: null,
      },
      showCancelReasonModal: {
        status: false,
        reason: null,
      },
      modalForm: '',
      modalTitle: '',
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    printForm() {
      return `${this.$store.state.app.baseURL}/exports/data/sales/saleForm/?id=${this.itemData.id}&token=${localStorage.getItem('downloadToken')}`
    },
    cancelRequest() {
      return this.$store.getters['saleOrderformsCancelPendings/dataCounts']
    },
  },
  created() {
    this.getCancelRequest()
  },
  methods: {
    getCancelRequest() {
      this.$store.dispatch('saleOrderformsCancelPendings/getDataList', {
        where: {
          'com_sale_orderform_delete_pending.id_com_sale_orderform': this.itemData.id,
          'com_sale_orderform_delete_pending.status !=': 3,
        },
      })
    },
    deliveryModal() {
      this.showModal = true
      this.modalForm = 'DeliveryForm'
      this.modalTitle = 'Teslimat Formu'
    },
    proformaModal() {
      this.showModal = true
      this.modalForm = 'ProformaForm'
      this.modalTitle = 'Proforma Fatura Formu'
    },
    sendCandeniz() {
      this.$store.dispatch('saleOrderforms/sendCandeniz', this.itemData.id)
    },
    showReasonModal() {
      this.showapproveReasonModal.reason = null
      this.showapproveReasonModal.status = true
    },
    showCancelModal() {
      this.showCancelReasonModal.reason = null
      this.showCancelReasonModal.status = true
    },
    sendCancelButton() {
      this.showapproveReasonModal.status = false
      this.$swal({
        title: 'Föy İptal isteğiniz gönderilecek',
        text: 'Föyün iptali için gerekli bildirimin yapılmasını onaylıyor musunuz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('saleOrderformsCancelPendings/sendRequest', {
            id_sale_orderform: this.itemData.id,
            reason: this.showCancelReasonModal.reason,
            id: this.itemData.id,
            status: '0',
          })
            .then(res => {
              console.log(res)
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: 'İşlem Başarılı',
                  text: 'Föy iptali isteğiniz bildirildi.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$router.push({ name: 'SaleOrderForms' })
              }
            })
        }
      })
    },
    sendApproveButton() {
      this.showapproveReasonModal.status = false
      this.$swal({
        title: 'Föy Tekrardan Yönetici Onayına Gönderilecek',
        text: 'Föyü tekrardan yönetici onayına göndermek istiyor musunuz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('saleOrderforms/saleOrderFormsSendApprove', {
            order_number: this.itemData.order_number,
            reason: this.showapproveReasonModal.reason,
            id: this.itemData.id,
            status: '0',
          })
            .then(res => {
              console.log(res)
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: 'İşlem Başarılı',
                  text: 'Föy tekrardan ilgili yönetici onayına gönderildi.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$router.push({ name: 'SaleOrderForms' })
              }
            })
        }
      })
    },
  },
}
</script>
