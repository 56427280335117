<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Yeni Araç Bilgileri</b-card-title>
    </b-card-header>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th class="align-middle width-200">
            Araç Modeli
          </b-th>
          <b-td>
            <div>{{ itemData.brand }} {{ itemData.model }}</div>
            <div class="font-small-2 text-warning">
              {{ itemData.hardware }}
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Yakıt - Vites
          </b-th>
          <b-td>{{ itemData.fuel }} {{ itemData.gear }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Silindir Hacmi
          </b-th>
          <b-td>{{ itemData.cc ? itemData.cc : '-' }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Renk
          </b-th>
          <b-td>{{ itemData.color }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Model Yılı
          </b-th>
          <b-td>{{ itemData.myear }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Şase - Motor No
          </b-th>
          <b-td>{{ itemData.chasis }} - {{ itemData.engine }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Satış Fiyatı
          </b-th>
          <b-td class="text-danger font-weight-bolder">
            {{ itemData.sale_price | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th
            class="align-middle"
            :class="itemData.licence_plate1 === '1'? 'text-danger' : ''"
          >
            Plaka Tercihi
          </b-th>
          <b-td
            class="font-weight-bolder"
            :class="itemData.licence_plate1 === '1'? 'text-danger' : ''"
          >
            {{ itemData.licence_plate_group? itemData.licence_plate_group : '-' }}
          </b-td>
        </b-tr>
      </b-thead>
    </b-table-simple>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle,
  BTableSimple, BTd, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'Customer',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
}
</script>
