<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Takas Araç Bilgileri</b-card-title>
    </b-card-header>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th class="align-middle width-200">
            Araç Modeli
          </b-th>
          <b-td>
            <div>{{ itemData.swap_brand }} {{ itemData.swap_model }}</div>
            <div class="font-small-2 text-warning">
              {{ itemData.hardware }}
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Yakıt - Vites
          </b-th>
          <b-td>{{ itemData.fuel }} {{ itemData.gear }}</b-td>
        </b-tr>
        <b-tr v-if="itemData.cc">
          <b-th class="align-middle">
            Silindir Hacmi
          </b-th>
          <b-td>{{ itemData.cc }}</b-td>
        </b-tr>
        <b-tr v-if="itemData.color">
          <b-th class="align-middle">
            Renk
          </b-th>
          <b-td>{{ itemData.color }}</b-td>
        </b-tr>
        <b-tr v-if="itemData.myear">
          <b-th class="align-middle">
            Model Yılı
          </b-th>
          <b-td>{{ itemData.myear }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Şase - Motor No
          </b-th>
          <b-td>{{ itemData.chasis }} - {{ itemData.engine }}</b-td>
        </b-tr>
        <b-tr v-if="itemData.license_plate">
          <b-th class="align-middle">
            Plaka
          </b-th>
          <b-td>{{ itemData.license_plate }}</b-td>
        </b-tr>
        <b-tr v-if="itemData.license_owner">
          <b-th class="align-middle">
            Ruhsat Sahibi
          </b-th>
          <b-td>{{ itemData.license_owner }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Ekspertiz
          </b-th>
          <b-td>{{ itemData.username }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Takas Bedeli
          </b-th>
          <b-td class="text-danger font-weight-bolder">
            {{ itemData.swap_price | toCurrency }} ₺
          </b-td>
        </b-tr>
      </b-thead>
    </b-table-simple>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle,
  BTableSimple, BTd, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'SwapCar',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
  },
  computed: {
    itemData() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
}
</script>
