<template>
  <div>
    <div v-if="!loading">
      <b-row>
        <b-col
          v-if="!proformaForm.customer.company_name"
          cols="12"
        >
          <b-form-group
            label="TC Kimlik No"
            label-for="tckn"
          >
            <b-form-input
              id="tckn"
              v-model="proformaForm.customer.tckn"
              placeholder="TC Kimlik No"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="proformaForm.customer.company_name"
          cols="12"
          md="6"
        >
          <b-form-group
            label="Vergi No"
            label-for="tax_number"
          >
            <b-form-input
              id="tax_number"
              v-model="proformaForm.customer.tax_number"
              placeholder="Vergi No"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="proformaForm.customer.company_name"
          cols="12"
          md="6"
        >
          <b-form-group
            label="Vergi Dairesi"
            label-for="tax_admin"
          >
            <b-form-input
              id="tax_admin"
              v-model="proformaForm.customer.tax_admin"
              placeholder="Vergi Dairesi"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="proformaForm.customer.company_name"
          cols="12"
        >
          <b-form-group
            label="Firma Adı"
            label-for="company_name"
          >
            <b-form-input
              id="company_name"
              v-model="proformaForm.customer.company_name"
              placeholder="Firma Adı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Adı Soyadı"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="proformaForm.customer.name"
              placeholder="Adı Soyadı"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Araç Modeli"
            label-for="model"
          >
            <b-form-input
              id="model"
              v-model="proformaForm.car.model"
              placeholder="Araç Modeli"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Yakıt Tipi"
            label-for="fuel"
          >
            <b-form-input
              id="fuel"
              v-model="proformaForm.car.fuel"
              placeholder="Yakıt Tipi"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Vites Tipi"
            label-for="gear"
          >
            <b-form-input
              id="gear"
              v-model="proformaForm.car.gear"
              placeholder="Vites Tipi"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Silindir Hacmi"
            label-for="cc"
          >
            <b-form-input
              id="cc"
              v-model="proformaForm.car.cc"
              placeholder="Silindir Hacmi"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Renk"
            label-for="color"
          >
            <b-form-input
              id="color"
              v-model="proformaForm.car.color"
              placeholder="Renk"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Model Yılı"
            label-for="myear"
          >
            <b-form-input
              id="myear"
              v-model="proformaForm.car.myear"
              placeholder="Model Yılı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Şase No"
            label-for="chasis"
          >
            <b-form-input
              id="chasis"
              v-model="proformaForm.car.chasis"
              placeholder="Şase No"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Motor No"
            label-for="engineno"
          >
            <b-form-input
              id="engineno"
              v-model="proformaForm.car.engineno"
              placeholder="Motor No"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Oto Bedeli"
            label-for="otobedeli"
          >
            <b-form-input
              id="otobedeli"
              v-model="proformaForm.carprice.otobedeli"
              placeholder="Oto Bedeli"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="ÖTV Oranı"
            label-for="otvorani"
          >
            <b-form-input
              id="otvorani"
              v-model="proformaForm.carprice.otvorani"
              placeholder="ÖTV Oranı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="ÖTV Tutarı"
            label-for="otvtutari"
          >
            <b-form-input
              id="otvtutari"
              v-model="proformaForm.carprice.otvtutari"
              placeholder="ÖTV Tutarı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="KDV Matrahı"
            label-for="kdvmatrahi"
          >
            <b-form-input
              id="kdvmatrahi"
              v-model="proformaForm.carprice.kdvmatrahi"
              placeholder="KDV Matrahı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="KDV Oranı"
            label-for="kdvorani"
          >
            <b-form-input
              id="kdvorani"
              v-model="proformaForm.carprice.kdvorani"
              placeholder="KDV Oranı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="KDV Tutarı"
            label-for="kdvtutari"
          >
            <b-form-input
              id="kdvtutari"
              v-model="proformaForm.carprice.kdvtutari"
              placeholder="KDV Tutarı"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Fatura Toplamı"
            label-for="faturatoplam"
          >
            <b-form-input
              id="faturatoplam"
              v-model="proformaForm.carprice.faturatoplam"
              placeholder="Fatura Toplamı"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-alert
        v-if="saveMessage.id"
        show
      >
        <div class="alert-body text-center">
          {{ saveMessage.message }}
        </div>
      </b-alert>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="success"
            class="mr-1"
            :disabled="!proformaForm.printable"
            :href="printUrl"
            target="_blank"
          >
            <FeatherIcon icon="PrinterIcon" />
            Yazdır
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="saveProformaForm"
          >
            <FeatherIcon icon="SaveIcon" />
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </div>
    <pre-loading v-else />
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton, BAlert,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'DeliveryForm',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert,
    PreLoading,
  },
  data() {
    return {
      loading: true,
      saveMessage: {},
    }
  },
  computed: {
    printUrl() {
      return `${this.$store.state.app.baseURL}/exports/data/sales/proformaForm/?id=${this.itemData.id}&token=${localStorage.getItem('downloadToken')}`
    },
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    proformaForm() {
      return this.$store.getters['saleOrderforms/proformaForm']
    },
  },
  created() {
    this.getProformaForm()
  },
  methods: {
    getProformaForm() {
      this.$store.dispatch('saleOrderforms/getProformaForm', this.itemData.id)
        .then(res => {
          if (res) {
            this.loading = false
          }
        })
    },
    saveProformaForm() {
      this.$store.dispatch('saleOrderforms/saveProformaForm', {
        id: this.itemData.id,
        proforma_form: this.proformaForm,
      })
        .then(res => {
          if (res.status) {
            this.getProformaForm()
          }
          this.saveMessage = res
        })
    },
  },
}
</script>
