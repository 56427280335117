<template>
  <validation-provider
    #default="{ errors }"
    name="ÖTV Oranı"
    rules="required"
  >
    <v-select
      id="id_com_otvrate"
      v-model="itemData.id_com_otvrate"
      label="title"
      :reduce="otvRate => otvRate.id"
      :options="otvRates"
      placeholder="Seçiniz"
    />
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'OtvRate',
  components: {
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    otvRates() {
      return this.$store.getters['otvRates/getOtvRates']
    },
  },
  created() {
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      return this.$store.dispatch('otvRates/otvRatesList')
    },
  },
}
</script>
