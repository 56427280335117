<template>
  <div>
    <div v-if="!loading">
      <b-row>
        <b-col
          v-if="!deliveryForm.customer.company_name"
          cols="12"
        >
          <b-form-group
            label="TC Kimlik No"
            label-for="tckn"
          >
            <b-form-input
              id="tckn"
              v-model="deliveryForm.customer.tckn"
              placeholder="TC Kimlik No"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="deliveryForm.customer.company_name"
          cols="12"
          md="6"
        >
          <b-form-group
            label="Vergi No"
            label-for="tax_number"
          >
            <b-form-input
              id="tax_number"
              v-model="deliveryForm.customer.tax_number"
              placeholder="Vergi No"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="deliveryForm.customer.company_name"
          cols="12"
          md="6"
        >
          <b-form-group
            label="Vergi Dairesi"
            label-for="tax_admin"
          >
            <b-form-input
              id="tax_admin"
              v-model="deliveryForm.customer.tax_admin"
              placeholder="Vergi Dairesi"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="deliveryForm.customer.company_name"
          cols="12"
        >
          <b-form-group
            label="Firma Adı"
            label-for="company_name"
          >
            <b-form-input
              id="company_name"
              v-model="deliveryForm.customer.company_name"
              placeholder="Firma Adı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Adı Soyadı"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="deliveryForm.customer.name"
              placeholder="Adı Soyadı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Cep Telefonu"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="deliveryForm.customer.phone"
              placeholder="Cep Telefonu"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="E-Posta"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="deliveryForm.customer.email"
              placeholder="E-Posta"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Adres"
            label-for="address"
          >
            <b-form-textarea
              id="address"
              v-model="deliveryForm.customer.address"
              placeholder="Adres"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Araç Modeli"
            label-for="model"
          >
            <b-form-input
              id="model"
              v-model="deliveryForm.car.model"
              placeholder="Araç Modeli"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Yakıt Tipi"
            label-for="fuel"
          >
            <b-form-input
              id="fuel"
              v-model="deliveryForm.car.fuel"
              placeholder="Yakıt Tipi"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Vites Tipi"
            label-for="gear"
          >
            <b-form-input
              id="gear"
              v-model="deliveryForm.car.gear"
              placeholder="Vites Tipi"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Silindir Hacmi"
            label-for="cc"
          >
            <b-form-input
              id="cc"
              v-model="deliveryForm.car.cc"
              placeholder="Silindir Hacmi"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Renk"
            label-for="color"
          >
            <b-form-input
              id="color"
              v-model="deliveryForm.car.color"
              placeholder="Renk"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Model Yılı"
            label-for="myear"
          >
            <b-form-input
              id="myear"
              v-model="deliveryForm.car.myear"
              placeholder="Model Yılı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Şase No"
            label-for="chasis"
          >
            <b-form-input
              id="chasis"
              v-model="deliveryForm.car.chasis"
              placeholder="Şase No"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Motor No"
            label-for="engineno"
          >
            <b-form-input
              id="engineno"
              v-model="deliveryForm.car.engineno"
              placeholder="Motor No"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-alert
        v-if="saveMessage.id"
        show
      >
        <div class="alert-body text-center">
          {{ saveMessage.message }}
        </div>
      </b-alert>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="success"
            class="mr-1"
            :disabled="!deliveryForm.printable"
            :href="printUrl"
            target="_blank"
          >
            <FeatherIcon icon="PrinterIcon" />
            Yazdır
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="saveDeliveryForm"
          >
            <FeatherIcon icon="SaveIcon" />
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </div>
    <pre-loading v-else />
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormTextarea, BRow, BCol, BButton, BAlert,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'DeliveryForm',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BAlert,
    PreLoading,
  },
  data() {
    return {
      loading: true,
      saveMessage: {},
    }
  },
  computed: {
    printUrl() {
      return `${this.$store.state.app.baseURL}/exports/data/sales/deliveryForm/?id=${this.itemData.id}&token=${localStorage.getItem('downloadToken')}`
    },
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    deliveryForm() {
      return this.$store.getters['saleOrderforms/deliveryForm']
    },
  },
  created() {
    this.getDeliveryForm()
  },
  methods: {
    getDeliveryForm() {
      this.$store.dispatch('saleOrderforms/getDeliveryForm', this.itemData.id)
        .then(res => {
          if (res) {
            this.loading = false
          }
        })
    },
    saveDeliveryForm() {
      this.$store.dispatch('saleOrderforms/saveDeliveryForm', {
        id: this.itemData.id,
        delivery_form: this.deliveryForm,
      })
        .then(res => {
          if (res.status) {
            this.getDeliveryForm()
          }
          this.saveMessage = res
        })
    },
  },
}
</script>
