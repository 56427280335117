<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Faturalandırma</b-card-title>
    </b-card-header>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th class="align-middle width-300">
            Oto Bedeli
          </b-th>
          <b-td>{{ itemData.invoiceUser.OtoBedeli | toCurrency }} ₺</b-td>
          <b-td><oto-bedeli /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            ÖTV Oranı
          </b-th>
          <b-td>% {{ itemData.invoiceUser.OtvOrani }}</b-td>
          <b-td><otv-orani /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            ÖTV Tutarı
          </b-th>
          <b-td>{{ itemData.invoiceUser.OtvTutari | toCurrency }} ₺</b-td>
          <b-td><otv-tutari /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            KDV Matrahından Düşülecek
          </b-th>
          <b-td />
          <b-td><kdv-dusulecek /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            KDV Matrahı
          </b-th>
          <b-td>{{ itemData.invoiceUser.KdvMatrahi | toCurrency }} ₺</b-td>
          <b-td><kdv-matrahi /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            KDV Oranı
          </b-th>
          <b-td>% {{ itemData.invoiceUser.KdvOrani }}</b-td>
          <b-td><kdv-orani /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            KDV Tutarı
          </b-th>
          <b-td>{{ itemData.invoiceUser.KdvTutari | toCurrency }} ₺</b-td>
          <b-td><kdv-tutari /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Araç Bedeli
          </b-th>
          <b-td>{{ itemData.invoiceUser.AracBedeli | toCurrency }} ₺</b-td>
          <b-td><arac-bedeli /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Plaka
          </b-th>
          <b-td>{{ itemData.invoiceUser.PlakaBedeli | toCurrency }} ₺</b-td>
          <b-td><plaka-bedeli /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Bandrol
          </b-th>
          <b-td>{{ itemData.invoiceUser.BandrolBedeli | toCurrency }} ₺</b-td>
          <b-td><bandrol-bedeli /></b-td>
        </b-tr>
        <b-tr>
          <b-th
            class="align-middle"
          >
            Araç Fatura Bedeli
            <div class="font-small-2 text-primary">
              (Plaka Bandrol Dahil)
            </div>
          </b-th>
          <b-td />
          <b-td><arac-fatura-bedeli /></b-td>
        </b-tr>
        <b-tr>
          <b-th
            class="align-middle"
          >
            Araç Satış Bedeli
            <div class="font-small-2 text-primary">
              (Aksesuar Dahil)
            </div>
          </b-th>
          <b-td />
          <b-td><arac-satis-bedeli /></b-td>
        </b-tr>
        <b-tr>
          <b-th
            class="align-middle"
          >
            PDI
            <div class="font-small-2 text-primary">
              (KDV Dahil Tutar Giriniz)
            </div>
          </b-th>
          <b-td />
          <b-td><pdi /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Bakiye
          </b-th>
          <b-td>{{ itemData.invoiceUser.Bakiye | toCurrency }} ₺</b-td>
          <b-td><bakiye /></b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            FF
            <div class="font-small-2 text-primary">
              (KDV Hariçtir)
            </div>
          </b-th>
          <b-td />
          <b-td>
            <fatura-farki />
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="align-middle">
            Not
          </b-th>
          <b-td colspan="2">
            <notes />
          </b-td>
        </b-tr>
      </b-thead>
    </b-table-simple>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BTableSimple, BThead, BTr, BTh, BTd,
} from 'bootstrap-vue'
import OtoBedeli from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/OtoBedeli.vue'
import OtvOrani from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/OtvOrani.vue'
import OtvTutari from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/OtvTutari.vue'
import KdvDusulecek from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/KdvDusulecek.vue'
import KdvMatrahi from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/KdvMatrahi.vue'
import KdvOrani from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/KdvOrani.vue'
import KdvTutari from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/KdvTutari.vue'
import AracBedeli from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/AracBedeli.vue'
import PlakaBedeli from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/PlakaBedeli.vue'
import BandrolBedeli from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/BandrolBedeli.vue'
import AracFaturaBedeli from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/AracFaturaBedeli.vue'
import AracSatisBedeli from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/AracSatisBedeli.vue'
import Pdi from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/Pdi.vue'
import Bakiye from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/Bakiye.vue'
import FaturaFarki from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/FaturaFarki.vue'
import Notes from '@/views/Sales/Sale_orderforms/View/InvoiceCalculator/Notes.vue'

export default {
  name: 'InvoiceCalculator',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    OtoBedeli,
    OtvOrani,
    OtvTutari,
    KdvDusulecek,
    KdvMatrahi,
    KdvOrani,
    KdvTutari,
    AracBedeli,
    PlakaBedeli,
    BandrolBedeli,
    AracFaturaBedeli,
    AracSatisBedeli,
    Pdi,
    Bakiye,
    FaturaFarki,
    Notes,
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    otvRates() {
      return this.$store.getters['otvRates/getOtvRates']
    },
    kdvRates() {
      return this.$store.getters['kdvRates/getKdvRates']
    },
  },
  watch: {
    itemData: {
      deep: true,
      handler(val) {
        if (val.invoice) {
          this.invoiceCalc(val.invoice)
        }
      },
    },
  },
  beforeCreate() {
    /*
      Önceki yazılımcıdan dua:
     أنوي حساب مدير النشرات في ميدوسا في سبيل الله
     */
  },
  methods: {
    invoiceCalc(invoice) {
      /* ÖTV Tutarı hesaplanıyor... */
      if (this.itemData.id_com_otvrate) {
        const otvRate = this.otvRates.find(e => e.id === this.itemData.id_com_otvrate).rate
        if (otvRate === '0') {
          this.itemData.invoice.otvtutari = 0
        } else {
          this.itemData.invoice.otvtutari = (invoice.otobedeli * otvRate) / 100
        }
      }
      if (this.itemData.id_com_kdvrate) {
        const kdvRate = this.kdvRates.find(e => e.id === this.itemData.id_com_kdvrate).rate
        if (kdvRate === '0') {
          this.itemData.invoice.kdvtutari = 0
        } else {
          this.itemData.invoice.kdvtutari = (invoice.kdvmatrahi * kdvRate) / 100
        }
      }

      this.itemData.invoice.id_com_otvrate = this.itemData.id_com_otvrate
      /* KDV matrahı hesaplanıyor */
      this.itemData.invoice.kdvmatrahi = (Number(invoice.otobedeli) + Number(invoice.otvtutari)) - Number(invoice.kdvdusulecek)
      /* KDV tutarı hesaplanıyor */

      /* Araç bedeli */
      this.itemData.invoice.aracbedeli = Number(invoice.kdvmatrahi) + Number(invoice.kdvtutari)
      this.itemData.invoice.aracfaturabedeli = Number(invoice.aracbedeli) + Number(invoice.plate_price) + Number(invoice.banderole_price)
      this.itemData.invoice.aracsatisbedeli = Number(invoice.aracfaturabedeli) + Number(invoice.aksesuarbedeli)
      this.itemData.invoice.bakiye = (Number(this.itemData.totalPayment) - Number(invoice.aracsatisbedeli)) + invoice.pdi
      if (Number(invoice.bakiye) > 0) {
        this.itemData.invoice.fatura_farki = invoice.bakiye
      }
      // Final
    },
  },
}
</script>
